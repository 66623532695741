<template>
  <transition name="modal-animation" @click.self="close">
    <div v-show="modalActive" class="modal z-50 w-full">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner md:w-2/5 shadow-lg rounded-lg">
          <!-- <i @click="close" class="far fa-times-circle"></i> -->
          <!-- <span @click="close">X</span> -->
          <!-- Modal Content -->
          <slot />
          <!-- <button @click="close" type="button">Close</button> -->
        </div>
      </transition>
    </div>
  </transition>
</template>
    
    <script>
import { computed, onMount } from "vue";
export default {
  props: ["modalActive"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return { close };
  },

  // watch: {
  //   modalActive: function (modalActive) {
  //     console.log("modal active");
  //     if (modalActive) {
  //       document.body.style.position = "fixed";
  //       document.body.style.top = `-${window.scrollY}px`;
  //     } else {
  //       const scrollY = document.body.style.top;
  //       document.body.style.position = "";
  //       document.body.style.top = "";
  //       window.scrollTo(0, parseInt(scrollY || "0") * -1);
  //     }

  //     console.log(modalActive, "something");
  //   },
  // },
};
</script>
    
    <style scoped>
.modal-open {
  overflow: hidden;
}

.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* width: 100vw; */
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);
}

.modal-inner {
  position: relative;

  overscroll-behavior: contain;

  /* max-width: 640px;
      width: 80%; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #f1f3f8;
  padding: 24px 16px;
}
/* i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
    }
    i:hover {
      color: crimson;
    } */

/* button {
      padding: 20px 30px;
      border: none;
      font-size: 16px;
      background-color: crimson;
      color: #fff;
      cursor: pointer;
    } */
</style>
    